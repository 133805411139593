<template>
  <div class="page">
    <ProfileCandidate :userId="$route.params.id"/>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileCandidate from '@/components/candidates/profile/ProfileCandidate';

export default {
  name: 'Candidate',
  components: {
    ProfileCandidate,
  },
  data() {
    return {
      mainTab: 0,
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
};
</script>
